import * as React from "react"
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import clsx from "clsx"
import productHeroArrowDown from "../assets/themes/onepirate/productHeroArrowDown.png"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.white,
      position: "relative",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        height: "80vh",
        minHeight: 500,
        maxHeight: 1300
      }
    },
    container: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(14),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    backdrop: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.5,
      zIndex: -1
    },
    background: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      zIndex: -2
    },
    arrowDown: {
      position: "absolute",
      bottom: theme.spacing(4)
    }
  })

interface ProductHeroLayoutProps extends WithStyles<typeof styles> {
  backgroundClassName: string
  children: any
  onArrowClick: () => void
}

const ProductHeroLayout = (props: ProductHeroLayoutProps) => {
  const { backgroundClassName, children, classes } = props

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        {children}
        <div className={classes.backdrop} />
        <div className={clsx(classes.background, backgroundClassName)} />
        <img
          className={classes.arrowDown}
          src={productHeroArrowDown}
          height="16"
          width="12"
          alt="arrow down"
          onClick={props.onArrowClick}
        />
      </Container>
    </section>
  )
}
export default withStyles(styles)(ProductHeroLayout)
