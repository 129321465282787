import * as React from "react"
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Container,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  Paper
} from "@material-ui/core"
import CourseOverviewItem from "./CourseOverviewItem"

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      position: "relative",
      maxWidth: 600
    },
    card: {
      minWidth: 275
      //marginTop: theme.spacing(8)
    },
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(0, 5)
    },
    // image: {
    //   height: 55
    // },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)"
    },
    title: {
      fontSize: 14
    }
  })

interface CourseOverviewProps extends WithStyles<typeof styles> {
  courseData: CourseIndex[]
}

const CourseOverview = (props: CourseOverviewProps) => {
  const [CourseOverviewExpanded, setCourseOverviewExpanded] = React.useState<any>(
    props.courseData.map<CourseIndexExpanded>(item => ({ ...item, expanded: true }))
  )

  const toggleExpanded = (module: CourseIndexExpanded) => {
    const courseIndex = CourseOverviewExpanded.map(item => {
      if (item == module) {
        return {
          ...item,
          expanded: !item.expanded
        }
      }
      return item
    })
    setCourseOverviewExpanded(courseIndex)
  }

  const { classes } = props
  return (
    <Container className={classes.container}>
      <Grid container spacing={5}>
        {CourseOverviewExpanded.map(data => (
          <Grid item key={data.module} xs={12}>
            <CourseOverviewItem CourseIndexItem={data} toggleExpand={toggleExpanded} key={data.module} />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default withStyles(styles)(CourseOverview)
