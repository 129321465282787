import * as React from "react"
import { createStyles, Theme, WithStyles, withStyles, Divider } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Snackbar from "@material-ui/core/Snackbar"
import Button from "@material-ui/core/Button"
import addToMailchimp from "gatsby-plugin-mailchimp"
import espImg from "../assets/wroom.png"
import { withSnackbar, WithSnackbarProps } from "notistack"
import { width } from "@material-ui/system"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(10),
      marginBottom: 0,
      display: "flex"
    },
    cardWrapper: {
      zIndex: 1
    },
    card: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#FFC071",
      padding: theme.spacing(8, 3)
    },
    cardContent: {
      maxWidth: 400
    },
    textField: {
      width: "100%",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    button: {
      width: "100%"
    },
    imagesWrapper: {
      position: "relative"
    },
    imageDots: {
      // position: "absolute",
      // top: -67,
      // left: -67,
      // right: 0,
      // bottom: 0,
      // width: "100%",
      background: "url(/static/onepirate/productCTAImageDots.png)"
    },
    image: {
      // position: "absolute",
      // top: -28,
      // left: -28,
      // right: 0,
      // bottom: 0,
      // width: "100%",
      maxWidth: 800,
      //maxHeight: 500
      //transform: "rotate(-90deg)",
      marginLeft: -50
      //marginTop: -40
    },
    notificationError: {
      backgroundColor: theme.palette.error.main,
      color: "#fff",
      padding: theme.spacing(1, 3, 1, 3),
      height: 48,
      borderRadius: 5
    }
  })

interface ProductCTAProps extends WithSnackbarProps, WithStyles<typeof styles> {}

const ProductCTA = (props: ProductCTAProps) => {
  const { classes } = props
  const [email, setEmail] = React.useState(false)

  const { enqueueSnackbar } = props

  const registerEmail = async event => {
    const data = await addToMailchimp(email)
    if (data.result == "success") {
      enqueueSnackbar(data.msg, {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "left" }
      })
    } else {
      enqueueSnackbar(data.msg, {
        persist: false,
        variant: "error",

        anchorOrigin: { vertical: "bottom", horizontal: "left" },
        children: key => (
          <div key={key} className={classes.notificationError} dangerouslySetInnerHTML={{ __html: data.msg }}></div>
        )
      })
    }
  }

  return (
    <Container className={classes.root} component="section">
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          //className={classes.cardWrapper}
        >
          <div className={classes.card}>
            <form className={classes.cardContent}>
              <Typography variant="h2" component="h2" gutterBottom>
                Receive News and offers
              </Typography>
              <Typography variant="h6">Get the latest ESP32 news and offers</Typography>
              <TextField
                className={classes.textField}
                placeholder="Your email"
                name="email"
                onChange={(e: any) => setEmail(e.target.value)}
              />
              <Button
                type="button"
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={registerEmail}
              >
                Keep me updated
              </Button>
            </form>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          //className={classes.imagesWrapper}
        >
          <Hidden smDown>
            <img src={espImg} alt="call to action" className={classes.image} />
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  )
}
export default withStyles(styles)(withSnackbar(ProductCTA))
