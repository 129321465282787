import * as React from "react"
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import ProductHeroLayout from "./ProductHeroLayout"
import { navigate } from "gatsby"

//import backgroundImage from "../assets/esp32-red.jpg"
import backgroundImage from "../assets/esp32-banner-v2.png"

const styles = (theme: Theme) =>
  createStyles({
    background: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundColor: "#7fc7d9", // Average color of the background image.
      backgroundPosition: "center"
    },
    button: {
      minWidth: 200
    },
    h5: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(10)
      }
    },
    more: {
      marginTop: theme.spacing(2)
    }
  })

interface ProductHeroProps extends WithStyles<typeof styles> {
  onArrowClick: () => void
}

function ProductHero(props: ProductHeroProps) {
  const { classes } = props

  return (
    <ProductHeroLayout backgroundClassName={classes.background} onArrowClick={props.onArrowClick}>
      <Typography color="inherit" align="center" variant="h2">
        Learn The ESP-IDF
      </Typography>
      <Typography color="inherit" align="center" variant="h5" className={classes.h5}>
        Your online resource to master the ESP32
      </Typography>
      <Button
        color="primary"
        variant="contained"
        size="large"
        className={classes.button}
        onClick={() => navigate("/course")}
      >
        View Course
      </Button>
      <Typography variant="body2" color="inherit" className={classes.more}>
        Initial Launch
      </Typography>
    </ProductHeroLayout>
  )
}

export default withStyles(styles)(ProductHero)
