import * as React from "react"
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Container,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button
} from "@material-ui/core"

const styles = (theme: Theme) =>
  createStyles({
    card: {
      minWidth: 275,
      marginTop: theme.spacing(8)
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)"
    },
    title: {
      fontSize: 14
    },
    quote: {
      borderLeft: `8px solid ${theme.palette.secondary.dark}`,
      paddingLeft: theme.spacing(8)
    },
    quoter: {}
  })

interface QuoteProps extends WithStyles<typeof styles> {
  quote: string
  author: string
}

const Quote = (props: QuoteProps) => {
  const { classes } = props
  return (
    <Container>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" component="h2" className={classes.quote}>
            {props.quote}
          </Typography>
          <Typography variant="subtitle1" className={classes.quote + " " + classes.quoter}>
            <i>{props.author}</i>
          </Typography>
        </CardContent>
      </Card>
    </Container>
  )
}
export default withStyles(styles)(Quote)
