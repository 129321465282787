import * as React from "react"
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import icons8_electronics_1px_6 from "../assets/icons/icons8_electronics_1px_6.png"
import icons8_training_1px from "../assets/icons/icons8_training_1px.png"
import productCurvyLines from "../assets/themes/onepirate/productCurvyLines.png"
import icons8_conference_background_selected_1px from "../assets/icons/icons8_conference_background_selected_1px.png"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      backgroundColor: theme.palette.secondary.light
    },
    container: {
      marginTop: theme.spacing(12),
      marginBottom: theme.spacing(12),
      display: "flex",
      position: "relative"
    },
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(0, 5)
    },
    image: {
      height: 55
    },
    title: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    },
    curvyLines: {
      pointerEvents: "none",
      position: "absolute",
      top: -180
    }
  })

interface ProductValuesProps extends WithStyles<typeof styles> {
  courseTime: CourseTime
}

const ProductValues = (props: ProductValuesProps) => {
  const { classes } = props

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img src={productCurvyLines} className={classes.curvyLines} alt="curvy lines" />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img className={classes.image} src={icons8_electronics_1px_6} alt="chip" />
              <Typography variant="h6" className={classes.title}>
                What is the ESP32
              </Typography>
              <Typography variant="h5">
                {`
                  The ESP32 is a low-cost, low-power system on a chip (SoC) with Wi-Fi and Bluetooth capabilities. It's a great device to
                  build IOT (Internet Of Things) projects.
                `}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img className={classes.image} src={icons8_training_1px} alt="training" />
              <Typography variant="h6" className={classes.title}>
                What we offer
              </Typography>
              <Typography variant="h5">
                {`
                  Our course covers ${/*props.courseTime.hour*/ 17} hours and ${
                  /*props.courseTime.min*/ 34
                } minutes of video content on the official ESP-IDF (IOT Developer Framework).

                `}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img className={classes.image} src={icons8_conference_background_selected_1px} alt="chip" />
              <Typography variant="h6" className={classes.title}>
                Who is this course for
              </Typography>
              <Typography variant="h5">
                {`
                  whether you are using the Arduino libraries and want better control your program, An enthusiast who know a little bit
                  of C, A technical entrepreneur or a professional embedded developer who needs to get up to speed fast.
                  This course is for you
                `}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}
export default withStyles(styles)(ProductValues)
