import * as React from "react"
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import devboard from "../assets/devboard.png"
import { navigate } from "gatsby"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(9)
    },
    button: {
      border: "4px solid currentColor",
      borderRadius: 0,
      height: "auto",
      padding: theme.spacing(2, 5)
    },
    link: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3)
    },
    devBoard: {
      transform: "rotate(90deg)",
      width: 250,
      marginTop: theme.spacing(-3)
    },
    standardPrice: {},
    starterPrice: {
      color: theme.palette.primary.main
    }
  })

interface ProductSmokingHeroProps extends WithStyles<typeof styles> {}

const ProductSmokingHero = (props: ProductSmokingHeroProps) => {
  const { classes } = props

  return (
    <Container className={classes.root} component="section">
      <Button className={classes.button} onClick={() => navigate("/stripe-checkout")}>
        <Typography variant="h4" component="span">
          Get It While it's HOT!!
        </Typography>
      </Button>
      <Typography variant="h6" className={classes.link}>
        While we are finalizing the course it will be on special!
      </Typography>
      <Typography variant="h5">
        Order now for only
        <span className={classes.standardPrice}>
          {" "}
          <s>$75.00</s>
        </span>
        <span className={classes.starterPrice}> $49.00 </span>
      </Typography>
      <img src={devboard} className={classes.devBoard} alt="devboard" />
    </Container>
  )
}
export default withStyles(styles)(ProductSmokingHero)
