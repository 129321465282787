import * as React from "react"
import { createStyles, Theme, WithStyles, withStyles, Button, Container, Typography, Paper } from "@material-ui/core"

import ProductHero from "./ProductHero"
import ProductValues from "./ProductValues"
import ProductCategories from "./ProductCategories"
import ProductHowItWorks from "./ProductHowItWorks"
import ProductCTA from "./ProductCTA"
import ProductSmokingHero from "./ProductSmokingHero"
import AppFooter from "./AppFooter"
import { ScrollTo } from "react-scroll-to"
import { navigate } from "gatsby"
import QuoteBlock from "./QuoteBlock"
import { StaticQuery, graphql } from "gatsby"
import { getVideoSlug } from "../utils/modelService"
import CourseOverview from "./courseData/CourseOverview"

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    button: {
      border: "4px solid currentColor",
      borderRadius: 0,
      height: "auto",
      padding: theme.spacing(2, 5)
    },
    quote: {}
  })

interface HomePageProps extends WithStyles<typeof styles> {}

const HomePage = (props: HomePageProps) => {
  const { classes } = props
  const [scrollToRegister, setScrollToRegister] = React.useState(null)
  const [scrollToProdVals, setScrollToProdVals] = React.useState(null)

  React.useEffect(() => {
    setScrollToRegister(document.getElementById("register").offsetTop)
    setScrollToProdVals(document.getElementById("prodVals").offsetTop)
  }, [])

  const getVideoStructure = (data: AllVideoData) => {
    const structure: CourseIndex[] = data.allModulesJson.nodes.reduce((prev, curr) => {
      const moduleVids = data.allVideosJson.nodes.filter(vid => vid.moduleId == Number(curr.id))
      const vids = moduleVids.map(vid => {
        return {
          title: vid.title,
          duration: vid.duration,
          isFree: vid.isFree,
          description: vid.description,
          url: getVideoSlug(Number(curr.id), vid.title)
        }
      })

      const module: CourseIndex = {
        module: curr.title,
        videos: vids
      }
      prev.push(module)
      return prev
    }, [])
    return structure
  }

  const getTime = (data: AllVideosJson) => {
    var totalSeconds = data.nodes.reduce((prev, curr) => curr.duration + prev, 0)
    var time: CourseTime = {
      hour: Math.floor(totalSeconds / 3600),
      min: Math.floor((totalSeconds / 3600) % 60),
      sec: Math.floor((totalSeconds % 3600) % 60)
    }
    return time
  }

  return (
    <StaticQuery
      query={query}
      render={data => {
        const videoStructure = getVideoStructure(data)
        const courseTime = getTime(data.allVideosJson)
        return (
          <div className={classes.root}>
            <React.Fragment>
              <ScrollTo>
                {({ scrollTo }) => (
                  <ProductHero
                    register={() => navigate("/sign-up")}
                    onArrowClick={() => scrollTo({ x: 0, y: scrollToProdVals, smooth: true })}
                  />
                )}
              </ScrollTo>
              <div id="prodVals">
                <ProductValues courseTime={courseTime} />
              </div>

              <QuoteBlock
                quote={`Best of the best course. What I am learning here, is way more then what I have learned in past 2 months from
            different resources. I have no words to say thanks to you for giving me access to this course`}
                author="Waqas"
              />

              <QuoteBlock
                quote={`Learnesp32.com is by far the best online course available to learn about ESP32-IDF.
          The course is well structured and easy follow. Mair (lecturer) is highly knowledgeable in his field of study.
          Would highly recommend this course to anyone interested in ESP32 programming.`}
                author="P. Mistry"
              />

              <QuoteBlock
                quote={`I was looking for a good way to refresh some skills during furlough due to COVID-19.
                I really appreciated the “Learn ESP32” online course. It was an excellent review of FreeRTOS and the basic and essential capabilities of the ESP32 offering from Espressif.
                I appreciated the delivery style and now use the materials as a resource when needed in my current developments. Excellent value.`}
                author="Michael Loh, Principal Consultant, DBML Group Inc."
              />

              <QuoteBlock
                quote={`LearnESP32 is by far the best resource I’ve found for learning how to program the esp32 using the ESP IDF! It covers everything from start to finish, including how to set up the IDF in visual studio and giving additional background lessons on trickier topics, which I really struggled to understand before this course. As an electronics student, I found that the tutorials on using FreeRTOS were particularly helpful and the skills I’ve learnt can easily be transferred, when working with other devices in the future. I would strongly recommend anyone buy a subscription to LearnESP32 if they want to make the most of the ESP32!  `}
                author="Emily Wayland"
              />

              <ProductSmokingHero />

              <CourseOverview courseData={videoStructure} />

              <div id="register">
                <ProductCTA />
              </div>

              {/* <ProductCategories />
        <ProductHowItWorks /> */}
            </React.Fragment>
          </div>
        )
      }}
    />
  )
}

export const query = graphql`
  query MyQuery {
    allVideosJson {
      nodes {
        title
        description
        duration
        moduleId
        isFree
      }
    }
    allModulesJson {
      nodes {
        title
        id
      }
    }
  }
`

export default withStyles(styles)(HomePage)
