import * as React from "react"
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Container,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  Paper,
  CardHeader,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { InputRow } from "aws-amplify-react"
import { Link } from "gatsby"

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(12),
      marginBottom: theme.spacing(12),
      display: "flex",
      position: "relative"
    },
    card: {
      minWidth: 275,
      marginTop: theme.spacing(8)
    },
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(0, 5)
    },
    image: {
      height: 55
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)"
    },
    title: {
      fontSize: 14
    },
    pos: {
      marginBottom: 12
    },
    heading: {
      fontWeight: theme.typography.fontWeightRegular,
      backgroundColor: theme.palette.secondary.light
      //color: theme.palette.common.white
    },
    textHeader: {
      fontSize: theme.typography.pxToRem(20)
    },
    linkText: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
      fontSize: theme.typography.pxToRem(15)
    },
    expansionDetails: {
      flexDirection: "column"
    },
    detailSection: {
      paddingBottom: 8
    }
  })

interface CourseOverviewItemProps extends WithStyles<typeof styles> {
  CourseIndexItem: CourseIndexExpanded
  toggleExpand: (module: CourseIndexExpanded) => void
}

const CourseOverviewItem = (props: CourseOverviewItemProps) => {
  const { classes } = props
  return (
    <Accordion expanded={props.CourseIndexItem.expanded} onChange={() => props.toggleExpand(props.CourseIndexItem)}>
      <AccordionSummary
        className={classes.heading}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.textHeader}>{props.CourseIndexItem.module}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionDetails}>
        {props.CourseIndexItem.videos.map(vid => (
          <div key={vid.url} className={classes.detailSection}>
            <Link to={`/${vid.url}`} className={classes.linkText}>
              {vid.title}
            </Link>
            <Typography color="textSecondary">{vid.description}</Typography>
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

export default withStyles(styles)(CourseOverviewItem)
